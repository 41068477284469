import React from "react";
import { Icons } from "../../components/Icons/Icon";
import { Footer } from "../../components/Footer/Footer";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import "./MyDetails.css";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { useDispatch, useSelector } from "react-redux";
import { setSignOut } from "../../../redux/slices/authSlice";
import { getInitialCharacter, titleCase } from "../../../utils/exportGlobleFunction";
import { GoToLogin } from "../../components/GoToLogin/GoToLogin";
import store from 'store'

export const MyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const tempLogin = useSelector((state) => state.auth);
  const userId = tempLogin?.tempLogin?.user?.data?.social_provider;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showLoginModal, setLoginModalShow] = useState(false);
  const handleLogOut = async () => {
    const fcmToken = localStorage.getItem('fcmToken');
    try {
      if (userId && userId?.user_id && userId.login_type) {
       
        const logOut = await exportApiAuth.logOut({
          user_id: userId?.user_id,
          login_type: userId?.login_type,
        });
        if (logOut.status === "success") {
          dispatch(setSignOut());
          localStorage.clear();
          store.clearAll()
           // Store the token in local storage
           localStorage.setItem('fcmToken', fcmToken);
          navigate("/login");
        } else {
          console.error("Something went wrong");
          dispatch(setSignOut());
          navigate("/login");
          store.clearAll()
          localStorage.clear();
        }
      } else {
        console.error("Missing user_id or login_type in userId");
      }
    } catch (error) {
      dispatch(setSignOut());
      navigate("/login");
      localStorage.clear();
      store.clearAll()
      // Store the token in local storage
      localStorage.setItem('fcmToken', fcmToken);
      console.error("error", error);
    }
  };

  const handleSpecificClick = (id) => {
    if (!!userId) {
      if (id === "myProfile") {
        navigate("/my-profile");
      }
      if (id === "listYourBusiness") {
        navigate("/more/list-your-business/form");
      }
      if (id === "career") {
        const storedName = localStorage.getItem("token");
        if (storedName) {
          alert(`token , ${storedName}!`);
        }
        navigate("/home/more/career");
      }
      if (id === "connect") {
        const storedName = localStorage.getItem("token");
        if (storedName) {
          alert(`token, ${storedName}!`);
        }
        navigate("/home/more/connect");
      }
    } else {
      setLoginModalShow(true);
    }
  };

  const MyBooking = () => {
    navigate("/my-booking");
  };

  const redirectToWhatsApp = () => {
    const phoneNumber = process.env.REACT_APP_WHATSAPP_PHONE_NUMBER;
      window.location.href = `https://wa.me/${phoneNumber}`;
  };

  return (
    <>
      {/*..................................................... Header ................................................*/}
      <div className="pb-16">
        {/*..................................................profile pic ................................................*/}

        <div className="img-fluid1  ">
          <div className="" style={{ marginTop: "50px", display: "flex" }}>
            <div
              className=" "
              style={{ position: "relative", display: "inline-block", marginLeft: "16px" }}
            >
              <div
                onClick={() => handleSpecificClick("myProfile")}
                className="profile-img-container rounded-circle cursor-pointer "
                style={{
                  border: "1px solid #D01414",
                  width: "72px",
                  height: "72px",
                  backgroundColor: "#252525",
                  color: "#ffffff",
                  fontSize: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {tempLogin.tempLogin?.user?.data?.display_name
                  ? getInitialCharacter(
                    tempLogin.tempLogin?.user?.data?.display_name
                  )
                  : getInitialCharacter("User")}
              </div>


            </div>
            <div className="ml-3">

              <h1 className="f-22 f-w600 mb-0  text-dark mt-2">
                {tempLogin.tempLogin?.user?.data?.display_name
                  ? titleCase(tempLogin.tempLogin?.user?.data?.display_name)
                  : "User"}
              </h1>
              <h1 className="f-14 f-w600 mb-0  text-dark mt-2" >
                {tempLogin.tempLogin?.user?.data?.email ? tempLogin.tempLogin?.user?.data?.email : "-"}
              </h1>
            </div>
          </div>
        </div>



        {/*..........................................List-Group....................................................*/}
        <div className="list-group pb-16" style={{ width: "-webkit-fill-available", maxWidth: "468px", paddingBottom: "100px" }}>
          <div
            className="list-group-item borderless  text-dark m-t-0 cursor-pointer"
            onClick={() => {
              MyBooking();
            }}
          >
            <div href="#" className=" mt-0 mb-0" style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="f-16">
                <Icons
                  name="my-bookings"
                  className=" svg-red svg-size-34 ml-0 mr-2  "
                />
                My Booking</div>
              <Icons
                name="arrow-right"
                className=" svg-red svg-size-16 ms-auto mr-2  align-self-center"
              />
            </div>
          </div>

          <div
            className="list-group-item borderless  text-dark cursor-pointer b-0"
            id="listYourBusiness"
            onClick={() => handleSpecificClick("listYourBusiness")}
          >
            <div href="#" className="mt-0 mb-0 " style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="f-16"> <Icons
                name="list-your-business"
                className=" svg-red svg-size-34 ml-0 mr-2 "
              />
                List Your Business</div>
              <Icons
                name="arrow-right"
                className=" svg-red svg-size-16 ms-auto mr-2 align-self-center "
              />
            </div>
          </div>

          <div
            href="#"
            className="list-group-item borderless  text-dark cursor-pointer "
            id="connect"
            onClick={() => handleSpecificClick("connect")}
          >
            <div href="#" className="mt-0 mb-0 " style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="f-16"> <Icons name="connect" className=" svg-red svg-size-34 ml-0 mr-2 " />
                Connect</div>
              <Icons
                name="arrow-right"
                className=" svg-red svg-size-16 ms-auto mr-2 align-self-center "
              />
            </div>
          </div>

          <div
            href="#"
            className="list-group-item borderless  text-dark cursor-pointer"
            id="career"
            onClick={() => handleSpecificClick("career")}
          >
            <div href="#" className="mt-0 mb-0 " style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="f-16"> <Icons name="career" className=" svg-red svg-size-34 ml-0 mr-2 " />
                Career</div>
              <Icons
                name="arrow-right"
                className=" svg-red svg-size-16 ms-auto mr-2 align-self-center "
              />
            </div>
          </div>

          <div
            href="#"
            className="list-group-item borderless  text-dark cursor-pointer"
            onClick={() => navigate("/home/more/about-us")}
          >
            <div href="#" className=" mt-0 mb-0" style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="f-16"> <Icons
                name="aboutUs"
                className=" svg-red svg-size-34 ml-0 mr-2 "
              />
                About Us</div>
              <Icons
                name="arrow-right"
                className=" svg-red svg-size-16 ms-auto mr-2  align-self-center"
              />
            </div>
          </div>

          <div
            href="#"
            className="list-group-item borderless  text-dark cursor-pointer"
            onClick={() => navigate("/home/more/term-condition")}
          >
            <div href="#" className=" mt-0 mb-0" style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="f-16">
                <Icons
                  name="terms-conditions"
                  className=" svg-red svg-size-34 ml-0 mr-2 align-self-center"
                />
                Terms & Conditions</div>
              <Icons
                name="arrow-right"
                className=" svg-red svg-size-16 ms-auto mr-2 align-self-center "
              />
            </div>
          </div>

          <div
            href="#"
            className="list-group-item borderless  text-dark cursor-pointer"
            onClick={() => navigate("/home/more/privacy-policy")}
          >
            <div href="#" className="mt-0 mb-0 " style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="f-16"><Icons
                name="privacy-policy"
                className=" svg-red svg-size-34 ml-0 mr-2 "
              />
                Privacy Policy</div>
              <Icons
                name="arrow-right"
                className=" svg-red svg-size-16 ms-auto mr-2 align-self-center "
              />
            </div>
          </div>

          <div
            href="#"
            className="list-group-item borderless  text-dark cursor-pointer"
            onClick={() => navigate("/home/more/delete-account")}
          >
            <div href="#" className="mt-0 mb-0 " style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="f-16"><Icons
                name="delete"
                className=" svg-red svg-size-34 ml-0 mr-2 "
              />
                Delete Account</div>
              <Icons
                name="arrow-right"
                className=" svg-red svg-size-16 ms-auto mr-2 align-self-center "
              />
            </div>
          </div>

          {/* <div
            href="#"
            className="list-group-item borderless  text-dark cursor-pointer"
            onClick={() => navigate("/home/more/Qr-Reader")}
          >
            <div href="#" className="mt-0 mb-0 " style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="f-16"><Icons
                name="delete"
                className=" svg-red svg-size-34 ml-0 mr-2 "
              />
                QR Scanner</div>
              <Icons
                name="arrow-right"
                className=" svg-red svg-size-16 ms-auto mr-2 align-self-center "
              />
            </div>
          </div> */}

          <div
            href="#"
            className="list-group-item borderless  text-dark cursor-pointer"
            onClick={() => redirectToWhatsApp()}
          >
            <div href="#" className="mt-0 mb-0" style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="f-16">
                <Icons
                  name="logout"
                  className=" svg-red svg-size-34 ml-0 mr-2 "
                />
                Help</div>
              <Icons
                name="arrow-right"
                className=" svg-red svg-size-16 ms-auto mr-2 align-self-center "
              />
            </div>
          </div>

          <div href="#" className="list-group-item borderless  text-dark cursor-pointer">
            <div
              href=""
              onClick={userId ? handleShow : () => navigate("/login")}
              className=" mt-0 mb-0"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="f-16">
                <Icons name="help" className=" svg-red svg-size-34 ml-0 mr-2 " />
                {userId ? "Logout" : "Login/SignUp"}</div>
              <Icons
                name="arrow-right"
                className=" svg-red svg-size-16 ms-auto mr-2 align-self-center "
              />
            </div>
          </div>
        </div>
      </div>
      {showLoginModal && (
        <GoToLogin
          show={showLoginModal}
          onHide={() => setLoginModalShow(false)}
          location={location}
        />
      )}
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body className="text-center logout-modal">
          <Icons name="logout1" className=" svg-red svg-size- ml-0 mr-2 " />
          <p className="logout-confirmation f-18 f-w600">Are you sure want to logout?</p>
          <div className="d-flex justify-content-evenly">
            <button
              className="bg-brand logout-modal-btn text-dark btn-fluid btn-width btn-color text-center justify-center f-16 "
              onClick={() => handleClose()}
            >
              Close
            </button>
            <button
              className="bg-brand logout-modal-btn text-dark btn-fluid btn-width text-center justify-center f-16 "
              onClick={() => handleLogOut()}
            >
              Yes, Logout
            </button>
          </div>
        </Modal.Body>

      </Modal>
      <Footer />
    </>
  );
};
