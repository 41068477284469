import { getAuth } from '@firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5u7b9QFjj6zV7JvBZLHhFpBQCmvz1oDI",
  authDomain: "indore-56d04.firebaseapp.com",
  projectId: "indore-56d04",
  storageBucket: "indore-56d04.appspot.com",
  messagingSenderId: "836325038426",
  appId: "1:836325038426:web:26aabb7939529d5fe77b63"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);

export default app;
