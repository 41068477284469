import React, { useState, useEffect } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase/firebase";
import { Icons } from "../../components/Icons/Icon";
import { useNavigate } from "react-router-dom";
import OtpInputField from "../../components/otpInput/OtpInput";
import { Button } from "../../components/form-type/button";
import LoginWithSocial from "../../components/loginWithSocial/LoginWithSocial";
import {
  setAgainRedirectionPath,
  setSignIn,
  setSignOut,
} from "../../../redux/slices/authSlice";
import { exportApi } from "../../../services/indoreTalk.api";
import { useDispatch, useSelector } from "react-redux";
import { isValidEmail } from "../../../utils/exportGlobleFunction";
import InputField from "../../components/form-type/InputField";
import { Toast } from "react-bootstrap";
import { getPlatform } from "../../../utils/constGlobleData";

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirectionPath } = useSelector((state) => state.auth);

  // eslint-disable-next-line no-unused-vars
  const [phone, setPhone] = useState("+91");
  const [inputParamsChange, setInputParamsChange] = useState(false);
  const [email, setEmail] = useState({
    email: "",
  });
  const [phoneNo, setPhoneNo] = useState({
    mobile_number: "",
  });
  const [emailAndPhoneBlocker, setEmailAndPhoneBlocker] = useState("");
  const [hasFilled, setHasFilled] = useState(false);
  const [fcmToken, setFcmToken] = useState(null); // State for FCM token
  const [loader, setLoader] = useState(false);
  const [loaderLogin, setLoaderLogin] = useState(false);
  const [showToast, setShowToast] = useState(false); // State variable for toaster message
  const [error, setError] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
    });
  };

  useEffect(() => {

    // Check if token is already in local storage
    const storedToken = localStorage.getItem('fcmToken');
    setFcmToken(storedToken); // Store token in state
    const userData = localStorage.getItem("tempLogin");
    const parse = JSON.parse(userData);
    const check = parse?.user?.data?.social_provider?.access_tokens;
    if (check) {
      navigate("/home"); // Redirect to home page if already authenticated
    }
  }, [navigate]);

  const validateIndianMobileNumber = (number) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(number);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setError("");
    if (!inputParamsChange) {
      setEmail({ ...email, [name]: value });
    } else {
      setPhoneNo({ ...phoneNo, [name]: value });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !error &&
      (validateIndianMobileNumber(phoneNo.mobile_number) ||
        validateEmail(email.email))
    ) {
      handleSend(e);
    } else if (inputParamsChange && !phoneNo.mobile_number) {
      setError("Please enter a mobile number.");
    } else if (!inputParamsChange && !email.email) {
      setError("Please enter a email.");
    } else if (
      inputParamsChange &&
      !validateIndianMobileNumber(phoneNo.mobile_number)
    ) {
      setError("Please enter a valid mobile number.");
    } else if (!inputParamsChange && !validateEmail(email.email)) {
      setError("Please enter a valid email.");
    }
  };

  const skipLogin = () => {
    dispatch(setSignOut());
    navigate("/onboard");
  };
  const [changePage, setChangePage] = useState(true);
  const handleSend = async (event) => {
    setError("");
    setLoaderLogin(true);
    event.preventDefault();
    if (inputParamsChange) {
      try {
        const otpSent = await exportApi.generateOtpWithWhatsapp(phoneNo);
        if (otpSent.status === 500) {
          setError("Something went wrong. Please try again after some time!");
          setLoaderLogin(false);
        } else if (
          otpSent?.data?.error &&
          otpSent?.data?.error ===
          "Failed to send OTP. Please enter Whatsapp number."
        ) {
          setError("Please enter valid Whatsapp number.");
          setLoaderLogin(false);
        } else if (otpSent) {
          setChangePage(false);
          setLoaderLogin(false);
          setHasFilled(true);
          setTimer(90);
        }
      } catch (error) {
        console.error(error);
        setLoaderLogin(false);
      }
    } else {
      try {
        const otpSent = await exportApi.generateOtp(email);
        if (otpSent.status === 500) {
          setError("Something went wrong. Please try again after some time!");
          setLoaderLogin(false);
        } else if (otpSent) {
          setChangePage(false);
          setLoaderLogin(false);
          setHasFilled(true);
          setTimer(60);
        }
      } catch (error) {
        console.error(error);
        setLoaderLogin(false);
      }
    }
  };

  const verifyOtp = async (otp) => {
    let fcm = { data: { token: null, device_type: null } }; // Initialize with defaults
    // Check if the platform is Android
    if (getPlatform() === "Android") {
      try {
        // eslint-disable-next-line no-undef
        const fcmIntentResult = await Android.fcmIntent(email?.email, 'Android');
        console.log(fcmIntentResult); // Only needed if fcmIntent is async and returns a result
      } catch (error) {
        console.error("Error in fcmIntent:", error);
      }
    } else if (getPlatform() === "Mac OS" || getPlatform() === "iOS") {
      // Post the message to the WebView
      window.webkit.messageHandlers.fcmToken.fcmMessage(email?.email, 'iOS');
    }
  
    // Attempt to get FCM token
    if (email?.email) {
      try {
        fcm = await exportApi.getFcmToken({ email: email?.email });
        await localStorage.setItem('fcmToken', fcm.data.token);
        await localStorage.setItem('device_type', fcm.data.device_type);
      } catch (error) {
        console.error("Error fetching FCM token:", error);
        // You can choose to set defaults or handle the error as needed
        fcm.data.token = null;
        fcm.data.device_type = null;
      }
    }
  
    setError("");
    setLoader(true);
    
    if (otp.length === 6) {
      try {
        const otpCheck = await exportApi.verifyOtp({
          ...(inputParamsChange
            ? {
              mobile_number: phoneNo.mobile_number,
              type: "mobile",
            }
            : {
              email: email.email,
              type: "google",
            }),
          otp: otp,
        });
  
        if (otpCheck?.message === "OTP verified successfully.") {
          let loginsignUp;
          if (inputParamsChange) {
            loginsignUp = await exportApi.signInAndSignUpWithWhatsapp({
              mobile_number: phoneNo.mobile_number?.startsWith("+91")
                ? phoneNo.mobile_number
                : `+91${phoneNo.mobile_number}`,
              login_type: "mobile",
              access_tokens: otpCheck?.accessToken,
              refresh_tokens: otpCheck?.accessToken,
              fcm_token: fcm.data.token, // Use the token from FCM
              device_type: fcm.data.device_type, // Use the device type from FCM
            });
          } else {
            loginsignUp = await exportApi.signInAndSignUp({
              login_type: "google",
              email: email?.email,
              access_tokens: otpCheck?.accessToken,
              refresh_tokens: otpCheck?.accessToken,
              fcm_token: fcm.data.token, // Use the token from FCM
              device_type: fcm.data.device_type, // Use the device type from FCM
            });
          }
  
          await dispatch(setSignIn(loginsignUp));
          if (loginsignUp.on_board_status === "signin" && loginsignUp.status === "success") {
            if (redirectionPath.path) {
              navigate(redirectionPath.path);
              setTimeout(() => {
                dispatch(setAgainRedirectionPath({ path: "" }));
              }, 2000);
            } else {
              navigate("/home");
            }
            setLoader(false);
          }
  
          if (loginsignUp.on_board_status === "signup" && loginsignUp.status === "success") {
            setTimeout(() => {
              setLoader(false);
              navigate("/my-profile");
            }, 1500);
          }
          setLoaderLogin(false);
        } else {
          setLoaderLogin(false);
          setLoader(false);
          setError(otpCheck?.data?.error);
        }
      } catch (error) {
        setLoader(false);
        setLoaderLogin(false);
        setError(error?.response?.data?.error);
        console.error(error);
      }
    } else {
      setLoader(false);
      setLoaderLogin(false);
    }
  };
  

  //otp start
  const [timer, setTimer] = useState(70);
  const num = timer > 0 ? timer : "Resend code";

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleResendCodeClick = (event) => {
    handleSend(event);
    // You can reset the timer and trigger the resend action
    setTimer(90);
  };

  const handleLoginClick = () => {
    // Now, you can use the inputs variable for further processing
    dispatch(setSignOut());
    setLoaderLogin(true);
    if (
      (Array.isArray(otp) &&
        otp.filter((value) => value !== "").length === 6) ||
      String(otp).replace(/\s/g, "").length > 5
    ) {
      verifyOtp(otp);
    } else {
      console.error("please enter full otp");
      setLoaderLogin(false);
      setLoader(false)
    }
  };
  const handleBackClick = () => {
    navigate(-1);
  };
  const handleOtpChange = (newInputValues) => {
    if (newInputValues.length <= 6) {
      setOtp(newInputValues);
    } else {
      return
    }
  };
  const inputParamsChangeFun = () => {
    setError("");
    setInputParamsChange(!inputParamsChange);
    setEmail({
      email: "",
    });
    setPhoneNo({
      mobile_number: "",
    });
  };

  //otp end
  if (!hasFilled && !loader && changePage) {
    return (
      <>
        <div className="app__container">
          <div className="text-center mt-5" style={{ marginTop: "80px" }}>
            <Icons name="splash1" className="login-logo" />
            <h1 className="sign-in-text"> Sign in</h1>
            <div className="mt-3">
              <form onSubmit={handleSubmit} className="login-outer" noValidate>
                <InputField
                  name={inputParamsChange ? "mobile_number" : "email"}
                  label={
                    <span className="text-disabled">
                      {inputParamsChange ? "Whatsapp Number" : "Email"}
                    </span>
                  }
                  type={inputParamsChange ? "number" : "email"}
                  value={
                    inputParamsChange ? phoneNo.mobile_number : email.email
                  }
                  error={error}
                  placeholder={`Please enter your ${inputParamsChange ? "whatsapp number" : "email"
                    }`}
                  autoComplete={"off"}
                  inputProps={{
                    style: { borderColor: "white" }, // Set the border color to white
                  }}
                  onChange={handleChange}
                />
                <div className="form-submit-btn mt-2">
                  <Button
                    className={` btn btn-secondary w-100 mt-1 p-2 px-3 z-index-999 btn-bg-red ${loaderLogin ? "btn-loading" : ""
                      }`}
                    loading={loaderLogin}
                    type="submit"
                    label="Login"
                  />
                </div>

                <hr />
              </form>
            </div>
            {/* <LoginWithSocial /> */}
            {/* skip button  */}
            <div className="d-flex justify-content-center mt-3">
              <div className="form-submit-btn">
                <Button
                  className="btn btn-secondary mb-3 p-2 px-3 z-index-999 btn-bg-red"
                  onClick={() => skipLogin()}
                >
                  Skip Login
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <h5>Read</h5>
              <h5
                className="pl-1 text-brand"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/home/more/term-condition")}
              >
                Terms of use
              </h5>
              <h5 className="pl-1">and</h5>
              <h5
                className="pl-1 text-brand"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/home/more/privacy-policy")}
              >
                Privacy policy
              </h5>
            </div>
          </div>

          <div id="recaptcha"></div>
        </div>
      </>
    );
  } else {
    return (
      <div className="app__container">
        <div>
          <div className="top-header-fix">
            <nav className="navbar top-nav navbar-light bg-light text-center ">
              <div className="container-fluid mx-auto text-center">
                <span
                  onClick={() => handleBackClick()}
                  className="cursor-pointer"
                >
                  <Icons name="back" className="svg-size-24 svg-white" />
                </span>
                <div className="m-auto ">
                  <h1 className="f-24 f-w600 mb-0">Verification</h1>
                </div>
              </div>
            </nav>
          </div>
          <section className="container-fluid  d-block">
            <div className="row justify-content-center">
              <div
                className="card  mb-5 mt-5 border-0"
                style={{ boxShadow: "0 12px 15px rgba(0, 0, 0, 0.02)" }}
              >
                <div className="card-body p-5 text-center">
                  <h1 className="f-24 text-dark">Verification</h1>
                  <h4 className="f-w400 mt-2 text-dark">
                    We have sent you the verification code{" "}
                    {inputParamsChange
                      ? "on your WhatsApp number"
                      : "on your Email"}
                  </h4>
                  <h4 className="f-w600 text-dark ">
                    {inputParamsChange ? phoneNo.mobile_number : email.email}
                  </h4>
                  <div className="otp-field mt-5">
                    <OtpInputField
                      value={otp}
                      onChange={handleOtpChange}
                      type="number"
                    />
                  </div>
                  <div className="mt-3">
                    {timer > 0 ? (
                      <h4 className="text-gray">
                        Resend code in{" "}
                        <span className="text-brand">{num}s</span>
                      </h4>
                    ) : (
                      <h4
                        className="text-brand "
                        onClick={(event) => handleResendCodeClick(event)}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Resend code
                      </h4>
                    )}
                  </div>
                </div>

                <Button
                  className={`button btn btn-primary btn-block ${loaderLogin ? "btn-loading" : ""
                    }`}
                  onClick={handleLoginClick}
                  label="Continue"
                  loading={loader}
                  error={error}
                />
                <div className="bottom-fix">
                  <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000000} autohide style={{ margin: "0 auto" }}>
                    <Toast.Body className="text-white text-center bg-red " >
                      Invalid OTP
                    </Toast.Body>
                  </Toast>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div id="recaptcha"></div>
      </div>
    );
  }
};
