/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Icons } from "../../components/Icons/Icon";
import "./MyProfile.css";
import { useNavigate } from "react-router-dom";
import { Container, Row, Toast } from "react-bootstrap";
import InputField from "../../components/form-type/InputField";
import { useDispatch, useSelector } from "react-redux";
import { exportApiAuth } from "../../../services/indoreTalkAuth.api";
import { setSignIn } from "../../../redux/slices/authSlice";
import {
  getInitialCharacter,
  titleCase,
} from "../../../utils/exportGlobleFunction";
import { exportApi } from "../../../services/indoreTalk.api";
import { Button } from "../../components/form-type/button";

export const MyProfile = () => {
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false); // State variable for toaster message
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const tempLogin = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  // const [errorMessage, setErrorMessage] = useState();
  const [formData, setFormData] = useState({
    display_name: tempLogin.tempLogin?.user?.data?.display_name || "",
    mobile_number: tempLogin.tempLogin?.user?.data?.mobile_number?.startsWith(
      "+91"
    )
      ? tempLogin.tempLogin?.user?.data?.mobile_number
      : `+91${tempLogin.tempLogin?.user?.data?.mobile_number === null
        ? ""
        : tempLogin.tempLogin?.user?.data?.mobile_number
      }` || "",
    email: tempLogin.tempLogin?.user?.data?.email,
    profile_pic: null,
    user_id: tempLogin.tempLogin?.user?.data?.social_provider?.user_id,
    login_type: tempLogin.tempLogin?.user?.data?.social_provider?.login_type,
    errors: {},
  });
  const navigate = useNavigate();
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const localData = localStorage.getItem("tempLogin");
  }, [user, navigate]);
  const handleBackClick = () => {
    navigate(-1);
  };
  const toastMessage = "";
  const updateProfile = async () => {
    setLoading(true);
    const errors = {};
    if (!formData?.mobile_number.trim()) {
      errors.mobile_number = "Mobile number can't be empty";
    } else if (!/^\+91[6-9]\d{9}$/.test(formData.mobile_number)) {
      errors.mobile_number = "Mobile number is invalid";
    } else {
      delete errors.mobile_number;
    }
    if (!formData.email) {
      errors.email = "Email address can't be empty";
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formData.email)) {
      errors.email = "Email address is invalid";
    } else {
      delete errors.email;
    }
  
    if (Object.keys(errors).length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        errors: errors,
      }));
      setLoading(false);
      return;
    }
  
    try {
      const login = await exportApiAuth.updateProfile(formData);
      if (login.status === "success") {
        
        setShowToast(true);
        let loginsignUp;
        if (formData.login_type === "mobile") {
          loginsignUp = await exportApi.signInAndSignUpWithWhatsapp({
            login_type: formData.login_type,
            email: formData?.email,
            mobile_number: formData?.mobile_number,
            access_tokens: tempLogin.tempLogin?.user?.data?.social_provider?.access_tokens,
            name: formData.display_name,
            refresh_tokens: tempLogin.tempLogin?.user?.data?.social_provider?.access_tokens,
          });
        }
        if (formData.login_type === "google") {
          loginsignUp = await exportApi.signInAndSignUp({
            login_type: formData.login_type,
            email: formData?.email,
            mobile_number: formData?.mobile_number,
            access_tokens: tempLogin.tempLogin?.user?.data?.social_provider?.access_tokens,
            name: formData.display_name,
            refresh_tokens: tempLogin.tempLogin?.user?.data?.social_provider?.access_tokens,
          });
        }
        dispatch(setSignIn(loginsignUp));
        if (loginsignUp) {
          navigate("/home");
        }
      } else {
        setShowToast(true);
       
      }
     
    } catch (error) {
      console.error("Error updating details:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "display_name") {
      const inputValue = e.target.value.replace(/[^a-zA-Z ]/g, "");
      setFormData({ ...formData, display_name: inputValue });
    } else if (name === "mobile_number") {
      if (e.target.value.length < 11) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value.slice(0, 10),
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const testNumber = "+914578923601";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (/^\+91\d*$/.test(value) && value.length <= 13) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  return (
    <>
      <Container>
        <div className="top-header-fix">
          <nav className="navbar top-nav navbar-light bg-light text-center ">
            <div className="container-fluid mx-auto text-center">
              <span
                onClick={() => handleBackClick()}
                className="cursor-pointer"
              >
                <Icons name="back" className="svg-size-24 svg-white" />
              </span>
              <div className="m-auto ">
                <h1 className="f-24 f-w600 mb-0">{"My Profile"}</h1>
              </div>
            </div>
          </nav>
        </div>

        <div className="text-center mt-3 ">
          <div
            className="profile-img-container"
            style={{ position: "relative", display: "inline-block" }}
          >
            <div
              className="profile-img rounded-circle"
              style={{
                height: "160px",
                width: "160px",
                border: "4px solid #D01414",
                marginTop: "30px",
                backgroundColor: "#252525",
                color: "#ffffff",
                fontSize: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {formData.display_name
                ? getInitialCharacter(formData.display_name)
                : getInitialCharacter("User")}
            </div>
          </div>
        </div>
        <div className="p-name container-fluid f-w600 f-24 text-center text-dark">
          {titleCase(formData.display_name) || "User"}
        </div>
        <Row>
          <div className="input-area mt-3">
            <div className="">
              <InputField
                label={<span className="text-disabled">Name</span>}
                type="text"
                placeholder="Name"
                name="display_name"
                value={formData.display_name}
                className="text-dark"
                onChange={handleChange}
              />

              <InputField
                label={<span className="text-disabled">Email-ID</span>}
                type="email"
                placeholder="Email-ID"
                name="email"
                className="text-dark"
                value={formData.email}
                onChange={handleChange}
                // readOnly={formData?.login_type === "google"}
              />
              {formData.errors?.email && (
                <p className="text-red mb-0">{formData.errors.email}</p>
              )}
              {/* {errorMessage?.email && (
                <p className="text-danger">{errorMessage.email[0]}</p>
              )} */}
              <div className="inputfield-height-with-validation-error">
                <InputField
                  name="mobile_number"
                  label={
                    <span className="text-disabled">
                      Contact No. <span className="text-red">*</span>
                    </span>
                  }
                  type="text"
                  placeholder="Contact No."
                  value={
                    // testNumber
                    formData.mobile_number.startsWith("+91")
                      ? formData?.mobile_number?.slice(0, 13)
                      : "+91" + formData?.mobile_number?.slice(0, 10)
                  }
                  onChange={(e) => {
                    if (e.target.value.startsWith("+91")) {
                      handleInputChange(e);
                    }
                  }}
                  // readOnly={formData?.login_type === "mobile"}
                />
                {formData.errors?.mobile_number && (
                  <p className="text-red mb-0">
                    {formData.errors.mobile_number}
                  </p>
                )}

                {/* {errorMessage?.mobile_number && (
                  <p className="text-danger">{errorMessage.mobile_number[0]}</p>
                )} */}
              </div>
            </div>
          </div>
        </Row>

        <Row>
          <div className="bottom-fix ">
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
              <Toast.Body className="text-white text-center bg-green">
                {tempLogin?.login?.status === "success"
                  ? "Profile Updated Successfully!"
                  :"The mobile number has already been taken."}
              </Toast.Body>
            </Toast>
          </div>
          <div className="form-submit-btn">
            <Button
              onClick={() => updateProfile()}
              className={`btn btn-secondary w-100 mt-3 mb-3 p-2 z-index-999 btn-bg-red ${loader ? "btn-loading" : ""
                }`}
              label="Save Changes"
            />
          </div>
        </Row>
      </Container>
    </>
  );
};
